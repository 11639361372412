.footer{z-index: 3;
	color: white;
	@include media-374 {
		font-size: 12px;	
	}
	.container {
		padding: 60px 85px 45px;
		z-index: 3;

		@media(max-width: 1499px) {
			padding: 60px 60px 45px;
		}
		@media(max-width: 1199px) {
			padding: 45px 15px 45px;
		}
		@media(max-width: 767px)  { 
			padding: 35px  15px 25px;
		}
	}
	h6{
		margin-bottom: 22px;
		@include media-767 {
			margin-bottom:15px;	
		}
		@include media-374 {
			margin-bottom:10px;	
		}
	}
	.footer_row{
		margin: 0 -80px;
		.f_logo img{
			@media(max-width: 767px)  {
				max-width:80%;
				width: 240px;
			}
		}
		@media(max-width: 1499px) {
			margin: 0 -50px;
		}
		@media(max-width: 1199px) {
			margin: 0 -40px;
		}
		@media(max-width: 991px) {
			margin: 0 -15px;
		}
		@media(max-width: 767px) {
			margin: 0 -15px;
			row-gap:35px;
		}
		.footer-item{
			&:not(:first-child){
				border-left: 1px solid $purple_02;
			}
			
			padding: 0 80px;
			@media(max-width: 1499px) {
				padding: 0 50px;
			}
			@media(max-width: 1199px) {
				padding: 0 40px;
			}
			@media(max-width: 991px) {
				padding: 0 15px;
			}
			.social-sec{
				gap: 8px;
				margin-top: 20px;
				li {
					a {
						background-color: $white;
						color: #3e3e3e;
			            display: flex;
			            align-items:center;
			            justify-content: center;
			            width: 54px; height: 54px;
			            border-radius: 50%;
			            font-size: $px22;
			            @media(max-width: 767px) {
							width: 44px;
							height: 44px;
						}
			            @media(max-width: 575px) {
							width: 40px;
							height: 40px;
						}
			            &:hover{
			              background-color: $secondary;
			              color: white;
			            }
					}
				}
			}
		}
	}
	.line-head{
		width: 100%;
		display: block;
		height: 1px;
		background-color: $purple_02;
		position: relative;
		margin-bottom: 25px;
		@include media-767 {
			margin-bottom:18px;	
		}
		@include media-374 {
			margin-bottom:12px;	
		}
		&:before{
			content: '';
			position: absolute;
			height: 100%;
			left: 0;
			top: 0;
			width: 30px;
			background-color: $yellow_02;
		}
	}
	
	.sitemap{
		p {
			margin: 0;
		}
		.location-contact{
			li {
				a:hover{
					color: $secondary;
				}
				.f_icon{
					max-width: 35px;
					width: 35px;
					font-size: $px22;
					color: $secondary;
				}
				&.contact-link{
					p {
						font-size: $px18;	

					}
				}	
				&.email-link{
					p {
						a {
							color: $yellow_02;
							text-decoration: underline;
							&:hover{
								color: $secondary;
							}
						}
					}
				}
				p{
					max-width: calc(100% - 35px);
					width: calc(100% - 35px);
				}
				+ li {
					margin-top: 30px;
					@media(max-width: 767px)  {
						margin-top: 25px;
					}
					@media(max-width: 574px)  {
						margin-top: 15px;
					}
				}
			}
		}
	}
	.copyrights{
		border-top: 1px solid $purple_02;
		margin-top: 40px;
		padding-top: 40px;
		* {
			margin: 0;
		}
		@media(max-width: 767px) {
			margin-top:0;
			padding-top : 30px
		}
	}
	.f_menus{
		ul {
			max-width: 130px;
			gap: 15px;
			li {
				a{
					&:hover{
						color: $secondary;
					}
				}
			}
		}
	}
}