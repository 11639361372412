@import 'slick-theme';
@import 'slick';

.main-section{
	padding-top: 0 !important;
	@media(max-width: 1199px) {
		padding-top: 70px
	}
	@media(max-width: 575px) {
		padding-top: 45px
	}
}
.heading{
	h6{
		color: rgba(0,0,0,.7);

	}
	&.text-center{
		h3 {
			&:before{
				right: 0;
				margin: 0 auto;
			}
		}
	}
}

.about-sec{
	padding: 0 0 90px;
	.row {
		@media(max-width: 991px) {
			gap: 30px
		}
	}
	figure{
		@media(max-width: 991px) {
			max-width: 80%;
			display:block; 
			margin:0 auto;
		}
	}
	@media(max-width: 1199px) {
		padding: 0 0 25px;
	}
	.about_right{
		padding: 0 70px;
		@media(max-width: 1199px) {
			padding: 0 45px
		}
		@media(max-width: 991px) {
			padding: 0 15px
		}
		@media(max-width: 767px) {
			
		}
	}
}

.marketing-sol {	
	.marketing-sol-in{
		padding: 240px 0;
		background-size: cover;
		clip-path: polygon(0 20%, 100% 0%, 100% 80%, 0% 100%);
		@media(max-width: 1499px) {
		    clip-path: polygon(0 20%, 100% 0%, 100% 90%, 0% 100%);
		}
		@media(max-width: 991px) {
		    clip-path: inherit !important;
		    padding: 100px 0
		}
		@media(max-width: 767px) {
		    clip-path: inherit !important;
		    padding: 70px 0
		}
		&:after ,
		&:before{
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background-color: $gray_dark;
			mix-blend-mode: multiply;
			
		}
		&:after {
			background-color: $purple;
			mix-blend-mode: initial;
			opacity: .8;
		}
	}


	.clip-path-top{
		/*z-index: 2;*/
		&:not(.clip-btm) {
		    top: -1px;
			clip-path: polygon(0 0, 0 100%, 100% 0);
		}

	}
	.clip-path-top-sm {
		clip-path: polygon(0 0, 0% 100%, 100% 100%);
		top: -102px;
		/*z-index: 3;*/
		background-color: #838383;

	}
	.clip-path-line-top {
		top: 5px;
		/*z-index: 3;*/
	}

	.marketing-text{
		z-index: 5;
		h3 {
			color: $white;
			strong {
				color: inherit;
			}
		}
		.market-items-wrap{
			padding-top: 20px;
			margin: 0 -25px;
			row-gap: 30px;
			.market-items{
				padding: 0 25px;
				.market-items-in{
					figure{
						border-radius: 15px;
						overflow: hidden;
					}	
					.market-txt{
						height: 115px;
						background-color: $gray_dark;
						color: $white;
						border-radius: 8px;
						margin-top: -50px;
						margin-left: 35px;
						transition: all .4s;
						max-width: calc(100% - 70px);
						@media(max-width: 575px) {
							height: 105px;
						}
						@media(max-width: 374px) {
							height: 90px;
							margin-top: -35px;
						}
						&:hover{
							box-shadow: 0px 10px 37px -10px rgba(242, 103, 93, 0.8);
							background-color: $secondary;
						}
						h5 {
							font-weight: 500;
							padding: 0 15px;
							width: 100%;
							strong{
								display: block;
							}
						}
					}	
				}
			}
		}
		
	}
}


.why-use{
	padding: 90px 0 120px;
    background: rgb(248, 248, 249);
    background: linear-gradient(0deg, rgb(248, 248, 249) 0%, rgb(255, 255, 255) 100%);
    .row{
    	margin: 0;
    }
    @media(max-width: 767px) {
	    padding: 60px 0;
	}
	.why-item{
		padding-top: 65px;
		margin-top: -1px;
		margin-left: -1px;
		position: relative;

		.overlayLogo {
			top: -34px;
			max-width: 90%;
			
			@media(max-width: 991px) {
				max-width: 70%;
			}
			@media(max-width: 575px) {
				z-index: -1;
			}
		}
		.why-icon{
			margin-bottom: 20px;
			display: block;
			
			svg , img {
				height: 80px;
			}

		}
		h5 {
			font-weight: 600;
			margin-bottom: 20px;
			@media(max-width: 575px) {
				height: auto !important;
				margin-bottom: 15px;
			}
		}
		* {
			position: relative;
			z-index: 3;
			transition: all .4s;
		}
		p{margin: 0}
		&:not(:first-child){
			padding: 65px 35px 50px;
			background-color: $white;
			border: 1px solid $gray_04;
			@media(max-width: 1499px) {
				padding: 50px 20px 30px;
			}
			@media(max-width: 575px) {
				padding-top:35px
			}
			&:before{
				content: '';
				height: calc(100% + 38px);
				width: calc(100% + 38px);
				left: -19px;
				top: -19px;
				position: absolute;
				background-color: $secondary;
				border-radius: 10px;
				z-index: 1;
				opacity: 0;
				transition: all .4s;
				@media(max-width: 767px) {
					height: calc(100% + 16px);
					width: calc(100% + 16px);
					left: -8px;
					top: -8px;
					border-radius: 5px;
				}
			}
			&:hover{
				.why-icon{
					svg {
						path {
							fill : $white;
						}
					}
				}
				&:before{
					opacity: 1;
				}
				* {
					color: $white;
				}
			}
		}
		
	}
}


.service-section {
	padding: 70px 0 285px;
	@media(max-width: 991px) {
		padding: 75px 0
	}
	@media(max-width: 767px) {
		padding: 60px 0
	}
	.heading { 
		h3 {
			strong{
				display: block;
			}
		}
	}
	.services{
		padding-top: 15px;
		gap: 15px;
		@media(max-width: 1199px) {
			flex-wrap:wrap;
			gap: 30px;
		}
		@media(max-width: 1199px) {
			gap: 10px;
			row-gap:20px;
		}
		.service_items{
			width: 100%;
			border-radius: 75px 0 75px 0;
			overflow: hidden;
			box-shadow: 0px 0px 40px -10px rgba(0, 0, 0, 0.4);
			max-width: 265px;
			@media(max-width: 1199px) {
				max-width: 250px;
			}
			@media(max-width: 575px) {
				max-width: 166px;
			}
			@media(max-width: 374px) {
				max-width: 100%;
				width: 260px
			}
			&:nth-child(odd) {
				margin-top: 80px;
				@media(max-width: 991px) {
					margin-top:0;
					border-radius: 50px 0 50px 0;
				}
			}
			figure{
				height: 235px;
				@media(max-width: 575px) {
					height:200px
				}
				
			}
			.service_txt{
				height: 175px;
				padding: 15px 25px;
				background-color: $white;
				@media(max-width: 575px) {
					height:145px;
					padding: 10px
				}
				.serviceIco{
					width: 80px; height: 80px;
					background-color: $secondary;
					border-radius: 50%;
					top: -45px;
					z-index: 1;
					font-size: 0;
					i{
						max-width: 70%;
						display: block;
					}
					&:before{
						content: '';
						position: absolute;
						width: 44px;
						height: 44px;
						background-color: $secondary;
						left: 0;
						top: 0;
						z-index: -1;
					}

				}
				h6{
					letter-spacing: -0.25px;
					font-weight: 300;
					strong{
						display: block;
						font-weight: 500;
					}
				}
			}
		}
	}
}

.review-section {
	padding: 40px 0 110px;
	background-color: $cream_color;
	@media(max-width: 991px) {
		padding: 60px 0
	}
}


.testimonial{
	padding: 0 10px;
	@media(max-width: 1679px) {
		max-width:90%; margin: 0 auto !important
	}
	@media(max-width: 1199px) {
		max-width:85%;
	}
	@media(max-width: 991px) {
		max-width:100%;
	}
	.slick-arrow:focus,
	.slick-arrow:hover , 
	.slick-arrow{
		background-color: transparent !important;
		width: 70px;
		height: 50px;
		background-image: url('../images/arrow_left.png');
		left: -70px;
		background-repeat: no-repeat;
		background-position:  center ;
		background-size: contain;
		
	}
	.slick-next:focus,
	.slick-next:hover , 
	.slick-next {
		right: -70px;
		left: auto;
		background-image: url('../images/arrow-rightt.png');
	}
	.testimonial-item{
		padding: 20px;
		@media(max-width: 991px) {
			padding: 10px;
		}
		.testimonial-item-in{
			background-color: $white;
			border-radius: 10px;
			border: 1px solid #ffebd5;
			padding: 50px 35px 95px;
			background-image: url('../images/qoute.png');
			background-repeat: no-repeat;
			background-position: right 25px bottom 25px;
			@media(max-width: 575px)  {
				padding:35px 20px 80px
			}
			.head_sec{
				margin-bottom: 25px;
				.head_img{
					max-width: 74px;
					width: 74px;
					border: 4px solid $secondary;
				}
				.head_name{
					max-width: calc(100% - 74px);
					width: calc(100% - 74px);
					padding-left:20px;
					h6{
						font-size: $px18;
						font-weight: 600;
						margin-bottom: 4px;
					}
					.star{
						margin-left: -2px;
						max-width: 111px;
						img{
							width: 111px;
							max-width: initial;
							object-fit: contain;
						}
					}

				}
			}
			p{
				color: #3b3b3b;
				margin: 0;
			}
		}
	}
}




.our-client{
	padding: 105px 0 110px;
	@media(max-width: 991px) {
		padding: 70px 0;
	}
	.client-logos {
		li {
			padding: 10px;
			.client_item{
				box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.2);
				height: 120px;
				width: 230px;
				max-width: 100%;
				border-radius: 3px;
				border: 1px solid #e4e4e4;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 20px;
				img{
					max-width: 100%;
				}
				@media(max-width: 575px) {
					height: 100px;
					padding: 10px 15px;
				} 
			}
		}
	}
}



.project-wrap{
	margin-top: 60px;
	row-gap: 45px;
	@media(max-width: 991px) {
		margin-top: 0;
	}
	.project-item{
		@media(max-width: 575px) {
			min-height: 480px;
		}
		@media(max-width: 374px) {
			min-height: 380px;
		}
		.project-item-in{
			box-shadow: 0px 0px 40px -10px rgba(0, 0, 0, 0.2);
			background-color: $white;
			padding: 30px 30px;
			border-radius: 160px 25px 25px 25px ;
			transition: all .4s;
			@include media-1199 {
				padding: 15px;
				border-radius: 100px 20px 20px 20px ;
			}
			@include media-991 {
				
			}
			@mixin media-575 {
				
			}
			@include media-374 {
				
			}
			figure{
				border-radius: 15px;
				overflow: hidden;
				transition: all .4s;
			}
			&:hover{
				figure{
					height: 100%;
				}

				.projectTxt{ 
					&:before {
						content: '';
						position: absolute;
						width: 3px;
						height: 90px;
						top: -30px;
						background-color: $white;
						left: 50%;
						transform: translateX(-50%);
						@include media-1199 {
							height: 60px;
						}
					}
					position: absolute;
					left: 50%;
					top: 50%;
					transform: translate(-50% , -50%);
					color: $white;
					border-radius: 15px;
					width: 75%;
					padding: 95px 35px 60px;
					background-color: rgba(242,103,93,.85);

					@include media-1199 {
						padding: 50px 15px 30px;
					}
					.btn-primary{
						display: inline-block;
					}
				}
			}
			.projectTxt{
				padding: 45px 30px 30px;
				@include media-1199 {
					padding: 20px 10px 10px;
				}
				@include media-991 {
					
				}
				@mixin media-575 {
					
				}
				@include media-374 {
					
				}
				.projectTxtIn{
					z-index: 3;
				}
				h4 {
					margin-bottom: 5px;
				}
				p{
					margin: 0;
				}
				.btn-primary{
					display: none;
					margin-top: 35px;
					border-color:#ffe000 ;
					background-color: #ffe000;
					color: $white;
					height: 52px;
					line-height: 50px;
					padding: 0 40px;
					@include media-1199 {
						margin-top: 20px;
					}
				}

			}
		}
	}
}

.latest-projects {
	padding: 110px 0 100px;
	background: rgb(255,255,255);
	background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(248,248,249,1) 100%);
	.btn-wrap{
		margin-top: 55px;
	}
	@media(max-width: 991px) {
		padding: 80px 0;
	}
	@media(max-width: 767px) {
		padding: 60px 0;
	}
	.heading  {
		h6 {
			max-width: 600px;
			margin: 0 auto;
		}
	}
}

.success-stories{
	height: 615px;
	@media(max-width: 991px) {
		height: 380px;
	}
	@media(max-width: 575px) {
		height: 320px;
	}

	.succes-text {
		top: 100px;
		z-index: 9;
		color: $white;
		text-align: center;
		@media(max-width: 575px) {
			top: 50%; transform:translateY(-50%)
		}
		h3 {
			margin: 0; padding: 0;
			color: $white;
			&:before{
				display: none;
			}
		}
		h2 {
			font-size: 90px;
			font-weight: 800;
			opacity: 1;
			color: $white;
			@media(max-width: 1499px) {
				font-size: 78px;
			}
			@media(max-width: 1199px) {
				font-size: 64px;
			}
			@media(max-width: 991px)  {
				font-size:54px;
			}
			@media(max-width: 767px)  {
				font-size: $px48;
			}
			@media(max-width: 575px)  {
				font-size: $px36;
			}
		}
		.btn{
			margin-top: 35px;
			@media(max-width: 575px) {
				margin-top: 20px;
			}
		}
	}
	
	figure{
	    clip-path: polygon(0 0, 100% 0%, 100% 72%, 0% 100%);
		height: 100%;
		&:after,
		&:before{
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background-color: $secondary;
			mix-blend-mode: multiply;
		}
		&:after{
			mix-blend-mode: initial;
			background-color: rgb(53,36,65);
			background: linear-gradient(90deg, rgba(53,36,65,0.85) 0%, rgba(242,102,93,0.85) 100%);
		}
	}
}


.our-blog{
	padding: 110px 0 125px;
	@media(max-width: 991px) {
		padding: 60px 0;
	}
	@media(max-width: 575px) {
		padding: 45px 0 35px;
		.container { 
			padding: 0
		}
	}
	.blog-wrap{
		padding: 0 90px;
		@media(max-width: 991px) {
			padding: 0;
		}
		@media(max-width: 767px) {
			&.sliderShow_3 {
				max-width: 80%;
			    margin: 0px auto !important;
			    .slick-arrow:focus, .slick-arrow:hover, .slick-arrow {
			    	right: -45px;
			    	width: 50px; height: 50px;
			    }
			    .slick-prev:focus, .slick-prev:hover, .slick-prev {
			    	left: -45px;
			    }
			}
		}
		@media(max-width: 575px) {
			&.sliderShow_3 { 
				max-width: 100%;
			}
		}
		.slick-slide{
			.blog-items ,
			> * {
				height: 100%;
			}
		}
		.blog-items{			
			padding:25px 20px;
			.blog-items-in{
				box-shadow: 0px 0px 24px -10px rgba(0,0,0,0.75);
				padding: 12px;
				border-radius: 22px;
				background-color: $white;
				figure{
					margin: 0;
					border-radius: 18px;
					overflow: hidden;
					height: 265px;
				}
				.blogtxt {
					height: calc(100% - 265px);
					position: relative;
					padding: 30px 20px 90px;
					@media(max-width: 575px) {
						padding: 20px 10px 80px;
					}
					h6{
						color: $orange_2;
						font-weight: 400;
						font-size: $px16;
						margin-bottom: 10px;
					}
					h5{
						font-weight: 600;
						font-size: $px22;
						color: $primary;
						margin-bottom: 20px;
						@media(max-width: 991px) {
							font-size: $px20;
						}
						@media(max-width: 767px) {
							font-size: $px18;
						}
						@media(max-width: 575px) {
							font-size: $px17;
						}
					}
					p{
						margin-bottom: 35px;
					}
					.btn{
						position: absolute;
						left: 20px;
						bottom: 40px;
					}
				}
			}
		}
	}
}
.news-event {
	background: rgb(248,248,249);
	background: linear-gradient(0deg, rgba(248,248,249,1) 0%, rgba(255,255,255,1) 100%);
	padding-top: 0;
    margin-top: 110px;
    @media(max-width: 991px) {
		margin-top: 60px;
	}
}
.slick-arrow:focus,
.slick-arrow:hover,
.slick-arrow{
	display: flex;
	align-items: center;
	justify-content: center;
	width: 72px; height: 72px;
	border-radius: 50%;
	background-color: $gray_01;
	right: 0;
	transition: all .4s;
	z-index: 3;
	&:before {
		display: none;
	}
	i {
		color: $gray_02;
		transition: all .4s;
		font-size: $px22;
	}
}
.slick-prev:focus,
.slick-prev:hover,
.slick-prev {
	left: 0;
}
.slick-arrow:hover{
    background-color: #f29136;
    i{
    	color: $white;
    }
}
.form-sec{
	position: relative;
	background: rgb(243,243,242);
	background: linear-gradient(0deg, rgba(243,243,242,1) 0%, rgba(230,231,229,1) 100%);
	padding: 135px 0 600px;
	z-index: 1;
	@media(max-width: 1199px) {
	    padding: 100px 0 500px;
	}
	@media(max-width: 991px) {
	    padding: 75px 0 300px;
	}
	@media(max-width: 767px) {
	    padding: 60px 0 210px;
	}
	&:before{
		content: '';
		position: absolute;
		left: 0;
		width: 100%;
		height: 100%;
		top: 0;
		background-image: url('../images/form-bg.png');
		background-position: center bottom;
		background-size: 100% auto;
		background-repeat: no-repeat;
	}
	h3 {
		strong{
			display: block;
		}
	}
}


.form{
	max-width: 950px;
	margin: 0 auto;
	row-gap: 30px;
	position: relative;
	@media(max-width: 767px) {
	    row-gap: 20px;
	}
	@media(max-width: 575px) {
	    row-gap: 15px;
	}
	.form-group{
		position: relative;
		@media(max-width: 575px) {
		    padding: 0;
		}
		label {
			font-weight: 600;
			color: #1e1e1e;
			margin-bottom:20px;
			font-size: $px18;
			opacity: .9;
			@media (max-width: 767px) {
				margin-bottom:10px;
				font-size: $px16;
			}
			@media (max-width: 374px) {
				margin-bottom:7px;
				font-size: $px15
			}
		}
		.form-icon{
			color: $gray_02;
			left: 45px;
			z-index: 3;
			font-size: 20px;
			+ textarea ,
			+ select ,
			+ input {
				padding-left: 70px;
				@media(max-width: 575px) {
					padding-left: 50px
				}
			}
			@media(max-width: 575px) {
			    left: 20px;
			}
		}
		&.message {
			.form-icon{
				top: 25px !important;
				transform: none !important;
			}
		}
		select {
			background-image: url('../images/arrow-down.png');
			background-position:  center right 20px;
			background-repeat: no-repeat;
		}
		select ,
		textarea ,
		input{
			width: 100%;
			background-color: $white;
			color: rgba(0,0,0,.5);
			border: none;
			border-radius:  4px;
			padding: 0 15px 0 35px;
			height: 64px;
			outline: none;
			filter: drop-shadow(10.607px 10.607px 7.5px rgba(0,0,0,0.03));
		}
		textarea {
			height: 240px;
			resize:none;
			padding-top: 25px; padding-bottom: 25px;
			@media(max-width: 767px) {
			   height: 210px;
			}
			@media(max-width: 575px) {
			   height: 170px;
			}
		}
		
	}
}

.contact-section{
	padding: 0 0 100px;
	@media (max-width: 991px) {
		padding-bottom: 75px;
	}
	@media (max-width: 768px) {
		padding-bottom: 60px;
	}
	@media (max-width: 575px) {
		padding-bottom: 45px;
	}
	@media (max-width: 374px) {
		padding-bottom: 35px;
	}
	.row {
		justify-content:center;
		row-gap: 26px;
		@media (max-width: 991px) {

		}
	}
	.contact_item {
		border: 1px solid #fac6c0;
		border-radius: 8px;
		background-color: #fdeae8;
		padding: 40px 25px;
		.contact_icon {
			margin-bottom: 10px;
			height: 65px;
			display: block;
			i{
				font-size: 55px;
				color: #f16359;
			}
		}
		h5{margin-bottom: 20px;
			font-weight: bold;
		}
		p{
			max-width: 270px;
			margin: 0 auto;
			a {
				font-weight: 600;
				&:hover{
					color: #f16359;
				}
			}
		}
	}
}

.conatact-form-sec {
	background: $white;
	background: linear-gradient(0deg, rgba(230,231,229,1) 0%, rgba(255,255,255,1) 100%);
	.heading{
		margin-bottom: 60px;
		@media (max-width: 768px) {
			margin-bottom: 50px;
		}
		@media (max-width: 575px) {
			margin-bottom: 40px;
		}
		@media (max-width: 374px) {
			margin-bottom: 25px;
		}
		h3 {
			strong{
				display: initial;
			}
		}
	}
}

.case-studies{
	padding-bottom: 280px;
	@media (max-width: 991px) {
		padding-bottom: 80px;
	}
	@media (max-width: 767px) {
		padding-bottom: 65px;
	}
	@media (max-width: 575px) {
		padding-bottom: 45px;
	}
	.project-wrap{

		.project-item{
			@media (max-width: 575px) {
				min-height: inherit !important
			}
			.project-item-in {
				figure{
					height: 450px;
					@media (max-width: 767px) {
						height: 380px;
					}
				}
				.projectTxt{
					h4{
						font-size: $px30;
						@media (max-width: 991px) {
							font-size: $px26;
						}
						@media (max-width: 767px) {
							font-size: $px24;
						}
						@media (max-width: 575px) {
							font-size: $px20;
						}
					}
					position: relative;
					&:before {
						content: '';
						position: absolute;
						width: 3px;
						height: 90px;
						top: -40px;
						background-color: $white;
						left: 50%;
						transform: translateX(-50%);
					}
					position: absolute;
					bottom: 70px;
					min-height: 260px;
				    left: 50%;
				    top: auto;
				    transform: translateX(-50%);
				    color: $white;
				    border-radius: 15px;
				    width: 75%;
				    padding: 75px 30px 40px;
				    background-color: $purple;
				    transition: all .4s;

				    @include media-1199 {
						width: 80%;
						padding-left: 15px; padding-right: 15px;
					}
					@media (max-width: 767px) {
						top:50%;
						bottom:auto; transform:translate(-50%, -50%);
					}
				}
				&:hover{
					.projectTxt{
						background-color: rgba(242, 103, 93, 0.85);
					}
				}
			}
		}
	}
	.projext_txt{
		padding: 90px 0 0;
		@media (max-width: 991px) {
			padding-top: 65px
		}
		@media (max-width: 767px) {
			padding-top: 50px
		}
		* {
			max-width: 970px;
			margin: 0 auto;
		}
		p{
			font-weight: 500;
			font-size: $px22;
			margin-bottom: 30px;
			@include media-1199 {
				font-size: $px20;
			}
			@include media-991 {
				font-size: $px21;
			}
			@include media-767 {
				font-size: $px18;
			}
			@include media-575 {
				font-size: $px16;
			}
		}
		h4{
			font-size: $px28;
			color: $secondary;
			font-weight: 700;
			@media (max-width: 991px) {
				font-size: $px24;
			}
			@media (max-width: 767px) {
				font-size: $px20;
			}
			@media (max-width: 575px) {
				font-size: $px18;
			}
			@media (max-width: 374px) {
				font-size: $px16;
			}
		}
	}
}

.client_slider{margin-bottom: 0}

.news-event-sec {

	.news-event-row {row-gap: 60px; margin-left: -25px; margin-right: -25px;
		@media (max-width: 1439px) {
			row-gap: 30px;
			margin-left: -15px; margin-right: -15px;
		}
	}
	.news-events-wrap{
		padding: 0 25px;
		@media (max-width: 1439px) {
			padding: 0 15px;
		}
		.news-events-item{
			height: 100%;
			box-shadow: 0px 0px 40px -10px rgba(0, 0, 0, 0.4);
			padding: 10px;
			border: 1px solid #e1e1e1;
			border-radius: 16px;
			@media (max-width: 1199px) {
			   display:block
			}
			@media (max-width: 575px) {
			  border-radius: 10px;
			}
			.news-event-img{
				
				font-size: 0;
				img {
					height: 100%;
					object-fit: cover;
					width: 100%;
					min-height: 545px;
					border-radius: 12px;
					@media (max-width: 1199px) {
						min-height: inherit;
						height: 300px
					}
					@media (max-width: 991px) {
					   	height: 240px
					}
				}
				.date{
					background-color: $yellow_02;
					padding: 10px 18px;
					top: 50px;
					right:-10px;
					color: $white;
					font-size: $px15;
					font-weight: 600;
					font-family: $secondary-font;
					&:after ,
					&:before{
						width: 0;
						height: 0;
						border-style: solid;
						border-width: 5px 0 5px 10px;
						border-color: transparent transparent transparent #fcac4b;
						content: '';
						position: absolute;
						top: -5px;
						right: 0;
						z-index: -1;
					}
					&:after  {
						top: auto;
						bottom: -5px;
					}
				}
			}
			.news-event-txt{
				padding: 28px 0 95px;
				padding-left: 50px !important;
				@media (max-width: 1439px) {
					padding-left: 30px !important;
				}
				@media (max-width: 1199px) {
					padding-left: 0 !important;
				}
				@media (max-width: 767px) {
					padding:20px 0 10px
				} 
				@media (max-width: 575px) {
					padding-bottom:5px
				} 
				h5{
					margin-bottom: 20px;
					font-size: $px25;
					color: $primary;
					font-weight: 800;
					@media (max-width: 1199px) {
						font-size: $px24;
					}
					@media (max-width: 991px) {
						font-size: $px22;
						margin-bottom: 15px;
					}
					@media (max-width: 767px) {
						font-size: $px20;
					}
					@media (max-width: 374px) {
						font-size: $px18;
						margin-bottom: 10px;
					}
				}
				p{
					margin-bottom: 35px;
					@media (max-width: 991px) {
						margin-bottom: 30px;
					}
					@media (max-width: 767px) {
						margin-bottom: 25px;
					}
					@media (max-width: 374px) {
						margin-bottom: 15px;
					}
				}
				.btn{
					position: absolute;
					left: 50px;
					bottom:35px;
					@media (max-width: 1439px) {
					    left: 30px;
					}
					@media (max-width: 1199px) {
					    left: 15px;
					}
					@media (max-width: 767px) {
						position:static
					}
				}
			}
		}
	}
	.pagination{
		margin-top: 65px;
		img{
			margin: 0 auto;
			display: block;
		}
	}
}


.more_info{
	padding: 115px 0 245px;
	@media (max-width: 1199px) {
		padding-top: 70px
	}
	@media (max-width: 991px) {
		padding: 70px 0
	}
	@media (max-width: 767px) {
		padding: 60px 0
	}
	@media (max-width: 575px) {
		padding: 45px 0
	}
	h4{
		font-weight: bold;
		font-size: $px40;
		margin-bottom: 25px;
		@media(max-width: 991px) {
			font-size:$px32
		}	
		@media(max-width: 767px) {
			font-size:$px26
		}	
		@media(max-width: 575px) {
			font-size:$px24
		}	
		@media(max-width: 374px) {
			font-size:$px18
		}	
	}
}


.gray_gradient_bg {
    background: rgb(248, 248, 249);
    background: linear-gradient(0deg, rgb(248, 248, 249) 0%, rgb(255, 255, 255) 100%);
}

.our-portFolio {
	padding: 105px 0;
	@media(max-width: 991px)  {
		padding: 75px 0;
	}
	@media(max-width: 767px)  {
		padding: 60px 0;
	}
	@media(max-width: 575px)  {
		padding: 45px 0;
	}
	@media(max-width: 374px)  {
		padding: 40px 0;
	}
}
.line {
	height: 1px;
	background-color: #d6d6d6;
	margin-bottom: 20px;
	display: block;
}
.website_highlight{
	padding: 30px;
	border-radius: 150px 30px 30px;
	background-color: $white;
	margin-top: 60px;
	box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
	@media(max-width: 767px) {
		padding:25px 15px;
		border-radius: 100px 20px 20px;
	}
}
.our-portFolio .pagination {
	margin-top: 85px ;
	@media (max-width: 991px) {
		margin-top: 60px ;
	}
	@media (max-width: 767px) {
		margin-top: 50px ;
	}
	@media (max-width: 575px) {
		margin-top: 35px ;
	}
	img{
		display: block;
		margin: 0 auto;
	}
}
.web_left {
	&.def_sty {padding-left: 20px;
		@media (max-width: 991px) {
			padding: 25px 0 0
		}
	}
	figure{
		@media (max-width: 991px) {
			max-width: 500px
		}
	}
	h4{
		font-weight: bold;
		font-size: $px40;
		margin-bottom: 25px;
		color: $primary;
		@media(max-width: 991px) {
			font-size:$px32
		}	
		@media(max-width: 767px) {
			font-size:$px26
		}	
		@media(max-width: 575px) {
			font-size:$px24
		}	
		@media(max-width: 374px) {
			font-size:$px22
		}	
	}

}
.def_sty{
	ul {
		margin-bottom: 30px;
		li {
			padding-bottom: 25px;
			padding-left: 45px;
			background-image: url('../images/list-icon.png');
			background-size: 33px 31px;
			background-repeat: no-repeat;
			background-position: top 5px left;
			font-size: 25px;
			@media(max-width: 1499px) {
				font-size:$px24
			}	
			@media(max-width: 1199px) {
				font-size:$px22
			}
			@media(max-width: 991px) {
				font-size:$px20;
				padding-bottom: 20px;
			}
			@media(max-width: 767px) {
				font-size:$px18;
				padding-bottom: 15px;
			}
			@media(max-width: 374px) {
				font-size:$px16
			}
			&:last-child {
				padding-bottom: 0;
				margin-bottom: 0;
			}
		}
	}
}


.intro-sec {
	padding-bottom: 90px;
	@media (max-width: 991px) {
		padding-bottom: 60px;
	} 
	@media (max-width: 767px) {
		padding-bottom: 45px;
	}
	p {
		font-weight: 500;
		font-size: $px20;
		&:last-child {
			margin-bottom: 0;
		}
		@media (max-width: 991px) {
			font-size: $px18;
		} 
		@media (max-width: 767px) {
			font-size: $px16;
		} 
		@media (max-width: 575px) {
			font-size: $px15;
		} 
		@media (max-width: 374px) {
			font-size: $px14;
		} 
	}
}


.website-sec {
	padding: 95px 0 120px;
	background: rgb(248,248,249);
	background: linear-gradient(180deg, rgba(248,248,249,1) 0%, rgba(248,248,249,1) 39%, rgba(255,255,255,0) 100%);
	@media(max-width: 991px) {
		padding: 70px 0;
	}
	@media(max-width: 767px) {
		padding: 60px 0;
	}
	@media(max-width: 575px) {
		padding: 45px 0;
	}
	+ .website-sec {
		padding-bottom: 320px;
		@media (max-width: 991px) {
			padding-bottom: 80px
		}
		@media (max-width: 767px) {
			padding-bottom: 60px
		}
		@media (max-width: 767px) {
			padding-bottom: 45px
		}
	}
	.website-wrap {
		padding: 95px 95px 60px;
		background-color: $white;		
		border: 1px solid $gray_05;
		@media(max-width: 1399px) {
			padding: 60px
		}
		@media(max-width: 1199px) {
			padding: 45px 20px
		}
		@media(max-width: 767px) {
			padding: 40px 15px
		}
		.websiteDetails{
			p {
				line-height: 2;
				font-size: $px18;
				@media(max-width: 767px) {
					font-size: $px16;
				}
				@media(max-width: 575px) {
					font-size: $px15;
				}
				&:last-child {
					margin-bottom: 0;
				}
			}
			+ div {
				@media(max-width: 991px) {
					order: -1 !important;
					max-width: 500px;
				}
			}
		}		

		@media(min-width: 991px) {
			.ord_desk_1{
			    order: -1;
				.webImg{
					padding-right: 60px;
					@media(max-width: 1199px) {
						padding-right: 0;
						margin-bottom:0;
					}
				}
			}
		}
		.webImg{
			padding-left: 60px;
			margin-bottom: -130px;
			@media(max-width: 1199px) {
				padding-left: 0;
				margin:0 0 20px
			}
			
			figure{
				overflow: hidden;
				border-radius: 0 0 100px;
				@media(max-width: 991px) {
					border-radius: 0 0 60px;
				}
				@media(max-width: 767px) {
					border-radius: 0 0 45px;
				}
				.serviceIco{
					width: 160px; height: 160px;
					background-color: $yellow_02;
					border-radius: 50%;
					top: 0;
					z-index: 1;
					font-size: 0;
					@media(max-width: 991px) {
						width: 120px; height: 120px;
					}
					@media(max-width: 767px) {
						width: 90px; height: 90px;
					}
					i{
						max-width: 70%;
						display: block;
						@media(max-width: 991px) {
							max-width: 60%;
						}
					}
					&:before{
						content: '';
						position: absolute;
						width: 88px;
						height: 88px;
						background-color: $yellow_02;
						left: 0;
						top: 0;
						z-index: -1;
						@media(max-width: 991px) {
							width: 60px;
							height: 60px;
						}
						@media(max-width: 767px) {
							width: 40px;
							height: 40px;
						}
					}

				}
			}
		}
	}
}

.focus_everywhere {
	margin-top: 115px;
	@media(max-width: 991px) {
		margin-top: 80px;
	}
	@media(max-width: 767px) {
		margin-top: 60px;
	}
	@media(max-width: 575px) {
		margin-top: 45px;
	}
	h4 {
		font-size: $px45 ;
		margin-bottom: 60px;text-transform: inherit;
		@media(max-width: 991px) {
			font-size: $px40 ;
		}
		@media(max-width: 767px) {
			font-size: $px34 ;
			margin-bottom: 40px;
		}
		@media(max-width: 575px) {
			font-size: $px28 ;
			margin-bottom: 30px;
		}
	}
	.focus_wrap{
		gap: 5px;
		&.no_shadow{
			.focus_item {
				box-shadow: none;
			}
		}
		.focus_item {
			padding: 50px 27px 40px;
			background-color: $white;
			border: 1px solid $white;
			box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.2);
			max-width: 20%;
			border-radius: 20px;
			width: calc(20% - 4px);
			@media(max-width: 1199px) {
				padding: 40px 15px;
			}
			@media(max-width: 991px) {
				max-width: calc(33.33% - 4px);;
				width: calc(33.33% - 4px);;

			}
			@media(max-width: 575px) {
				max-width: calc(50% - 3px);;
				width: calc(50% - 3px);
				padding: 25px 15px;
			}
			.f_icon{
				font-size: 0;
				margin-bottom: 20px;
				display: block;
				height: 81px;
				img{max-width: 100%; max-height: 100%}
				@media(max-width: 767px) {
					height: 70px;
				}
				@media(max-width: 575px) {
					height: 60px;
				}
			}
			h5 {
				text-transform: uppercase;
				line-height: 1.3;
				color: $black;
				font-weight: bold;
				margin: 0;
			}
			h6{
				font-size: $px21;
				text-transform: uppercase;
				margin: 0;
				font-weight: 600;
				@media(max-width: 1439px) {
					font-size: $px20;
				}
				@media(max-width: 1199px) {
					font-size: $px18;
				}
				@media(max-width: 767px) {
					font-size: $px16;
				}
			}
		}	
	}
}



.we_provide_sec{
	.provideTxt{
		max-width: 890px; margin: 0 auto;
	}
	.we_provide_row{
		margin-bottom:85px;
		@media(max-width: 767px) {
			margin-bottom: 40px;
		}
		.feel_good_sec{
			background-color: $white;
			@media(max-width: 767px) {
				height: auto !important;
			}
			.sm_heading {
				background-color: $secondary;
				height: 110px;
				@media(max-width: 991px) {
					height: 90px;
				}	
				@media(max-width: 767px) {
					height: 75px;
				}
				@media(max-width: 575px) {
					height: 60px;
				}
				h3 {
					color: $white;
					padding: 0;
					font-size: 50px;
					line-height: 1.2;
					font-weight: bold;
					margin: 0;
					&:before{
						display: none;
					}
					@media(max-width: 1499px) {
						font-size:$px45
					}	
					@media(max-width: 1199px) {
						font-size:$px40
					}	
					@media(max-width: 991px) {
						font-size:$px36
					}	
					@media(max-width: 767px) {
						font-size:$px30
					}
					@media(max-width: 575px) {
						font-size:$px24
					}	
				}
			}
			.sm_content{
				padding: 20px;
				border-color: $gray_04;
				height: calc(100% - 110px);
				figure{height: 100%}
				@media(max-width: 767px) {
					height: inherit;
					padding: 15px
				}
				ul {
					width: 50%;
					max-width: 50%;
					@media(max-width: 575px) {
						width: 100%;
						max-width: 100%;
					}	
					li {
						font-family: $secondary-font;
						color: $white;
						font-size: $px30;
						line-height: normal;
						font-weight: 600;
						background-image: url('../images/star_pattern.png');
						background-position: left top 3px;
						background-repeat: no-repeat;
						padding-left: 35px;
						@media(max-width: 1599px) {
							font-size: $px28;
						}
						@media(max-width: 1399px) {
							font-size: $px24;
						}
						@media(max-width: 1199px) {
							font-size: $px22;
						}
						@media(max-width: 767px) {
							font-size: $px20;
						}
						@media(max-width: 575px) {
							font-size: $px18;
							margin-top:20px;
							background-position: left top 1px;
						    background-size: 17px auto;
						}
						+ li {
							margin-top: 30px;
							@media(max-width: 575px) {
								margin-top:20px;
							}
						}
					}
				}
			}
			&.confident_sec{
				background-color:$purple;
				.sm_content{
					border: none;
					padding: 60px 50px;
					color: $white;
					@media(max-width: 1439px) {
						padding: 40px 30px
					}
					@media(max-width: 1199px) {
						padding: 40px 20px
					}
					@media(max-width: 575px) {
						padding-top: 20px;
					}
				}
			}
		}
	}
	.isea-logo{
		bottom: -45px;
	    border-radius: 15px;
    	padding: 25px;
    	font-size: 0;
    	background-color: #fff;
		@media(max-width: 767px) {
			display: none !important
		}
		img{
			@media (max-width: 1199px) {
				max-width: 260px
			}
		}
	}
}
.monthly-pattern-heading{
	padding-top: 120px;
	border-top: 3px solid #c0b9c3;
	@media (max-width: 767px) {
		padding-top: 60px;
	}
	@media (max-width: 575px) {
		padding-top: 45px;
	}
	&:before{
		content: '';
		position: absolute;
		left: 0;
		top: -6px;
		width: 100%;
		height: 1px;
		background-color: #968b9c;
	}
	> * {
		max-width: 645px;
		margin: 0 auto;
	}
	h4 {
		margin-bottom: 40px;
	}
	h5 {
		margin-bottom: 40px;
		font-weight: bold;
		color: $secondary;
		a {
			color: inherit;
			text-decoration: underline;
		}
	}

}
.monthly-reports-sec{
	padding: 105px 0 260px;
	@media (max-width: 991px) {
		padding-top: 75px;
		padding-bottom: 80px;
	}
	@media (max-width: 767px) {
		padding-top: 60px;
		padding-bottom: 65px;
	}
	@media (max-width: 575px) {
		padding-top: 45px;
		padding-bottom: 45px;
	}
	.monthly-pattern figure{
		margin: 0 auto;
		display: block;
		max-width: 94%;
	}
}
.monthly_img{
	background-color: $white;
	padding: 16px;
	max-width: 785px;
	margin: 0 auto -230px;
	@media (max-width: 991px) { 
		margin-bottom:0
	}
	font-size: 0;
	z-index: 1;
    position: relative;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.2);
}
.monthly-txt{
	background: rgb(245,177,172);
	background: linear-gradient(180deg, rgba(245,177,172,1) 0%, rgba(255,255,255,1) 75%);
	padding: 280px 70px 50px;
	p {
		font-size: $px20;
		@media (max-width: 991px) {
			font-size: 18px;
		}
		@media (max-width: 767px) {
			font-size: 16px;
		}
		@media (max-width: 575px) {
			font-size: 15px;
		}
	}
	@media (max-width: 991px) {
		padding: 45px 25px
	} 
	@media (max-width: 767px) {
		padding: 40px 15px
	} 
}

.investment-sec{
	padding: 106px 0;
	@media (max-width: 991px) {
		padding: 75px 0;
	}
	@media (max-width: 767px) {
		padding: 60px 0;
	}
	@media (max-width: 575px) {
		padding: 45px 0;
	}
}
.call-tracking{
	padding: 65px 80px 0;
	margin-bottom: 90px;
	margin-top: 40px;
	background-color: $yellow_02;
	@media (max-width: 1399px) {
		padding: 50px 40px 0;
	}
	@media (max-width: 1199px) {
		padding: 45px 15px 0;
	}
	@media (max-width: 767px) {
		padding: 60px 15px 0;
		margin-bottom: 0px;
	}
	@media (max-width: 575px) {
		padding: 45px 15px 0;
	}
	h4 {
		font-size: $px45;
		margin-bottom: 40px;
		text-align: center;
		@media (max-width: 1399px) {
			font-size: $px40;
		}
		@media (max-width: 1199px) {
			font-size: $px36;
		}
		@media (max-width: 991px) {
			font-size: $px30;
		}
		@media (max-width: 767px) {
			font-size: $px27;
			margin-bottom:30px;
		}
		@media (max-width: 575px) {
			font-size: $px24;
			margin-bottom:25px;
		}
	}
	.track-wrap {
		gap: 6px;
		@media (max-width: 1199px) { 
			padding-bottom: 25px
		}
		.track_item {
			padding: 12px;
			padding-bottom: 40px;
			border-radius: 12px;
			background-color: $white;
			max-width: calc(25% - 5px);
			width: calc(25% - 5px);
			margin-bottom: -90px;
			box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.1);
			@media (max-width: 991px) {
				padding-bottom: 20px;
			}
			@media (max-width: 767px) {
				max-width: calc(50% - 5px);
				width: calc(50% - 5px);
				margin-bottom:0
			}
			@media (max-width: 575px) {
				max-width: 260px;
				width: 100%;
				margin-bottom:0
			}
			.track_item_img{
				margin-bottom: 40px;
				background-color: #f4f4f4;
				border-radius: 10px 10px 0 0;
				height: 225px;
				@media (max-width: 1399px) {
					height: 200px;
				}
				@media (max-width: 1199px) {
					height: 188px;
					margin-bottom: 30px;
				}
				@media (max-width: 991px) {
					height: 136px;
					margin-bottom: 20px;
				}
				@media (max-width: 575px) {
					height: 150px;
					margin-bottom: 20px;
				}
				figure{
					display: block;
					margin: 0 auto;
					height: 100%;
					display: flex;
					align-items: flex-end;
					justify-content: center;
					img {
						max-height: 90%;
					}
				}
				.numb{
					width: 78px;
					height: 78px;
					border-radius: 50%;
					background-color: #ef665c;
					font-weight: bold;
					font-size: 50px;
					color: $white;
					left: -20px;
					top: 18px;
					display: flex;
					align-items: center;
					justify-content: center;
					@media (max-width: 991px) {
						width: 60px;
						height: 60px;
						left: -15px;
						top: -7px;
						font-size:$px30
					}
				}
			}
			h5{
				font-size: $px24;
				color: #45444c;
				font-family: $secondary-font;
				font-weight: 600;color: #000;
				max-width: 225px;
				margin: 0 auto;
				text-align: center;
				@media (max-width: 1199px) {
					font-size: $px22;
				}
				@media (max-width: 991px) {
					font-size: $px20;
				}
				@media (max-width: 767px) {
					font-size: $px18;
				}
				@media (max-width: 375px) {
					font-size: $px16;
				}
			}
		}
	}
}


.tracking-sec {
	padding: 95px 0;
	background-color: $cream_color;
	@media (max-width: 991px) {
		padding: 75px 0;
	}
	@media (max-width: 767px) {
		padding: 60px 0;
	}
	@media (max-width: 575px) {
		padding: 45px 0;
	}
	.row{
		margin-top: 40px;
	}
	&.gray-bg{
		background: $white;
		background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(248,248,249,1) 100%);
		.trackIMG{
			border-radius: 20px 150px 20px 20px;
			margin: 0;
		}
	}
	.trackTxt{
		p {
			font-size: 18px;
			line-height: 2;
			&:last-child {
				margin-bottom: 0;
			}
			@media (max-width: 767px) {
				font-size: 16px;
			}
			@media (max-width: 575px) {
				font-size: 15px;
			}
		}	
		@media (max-width: 991px) {
			order : 2
		}
	}
	.trackIMG {
		margin: 0 auto;
		max-width: 640px;
		background-color: $white;
		padding: 30px;
		box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.1);
		border-radius: 150px 20px 20px;
		margin-bottom: -140px;
		@media (max-width: 991px) {
			margin:0 auto 25px !important
		}
		img {
		}
	}
}