
/*button Style*/
.btn{
    white-space: nowrap;

	&.btn-outline{
		height: 56px;
		border-radius: 5px;
		background-color: $white;
		border: 1px solid #5d5e5e;
		font-weight: 500;
		font-family: $secondary-font;
		line-height: 54px;
	    padding: 0 35px;
	    color: $secondary;
		&:hover{
			background-color: $secondary;
			border-color: $secondary;
			color: $white;
		}
		&.btn-red {
			border-color: $secondary;
			height: 50px; 
			line-height: 48px;
		 	padding: 0 40px;
		}
	}
	&.btn-primary{
		height: 72px;
		border: 1px solid $secondary;
		background-color: $secondary;
		border-radius: 36px;
		padding: 0 60px;
		line-height: 70px;
		color: $white;
		font-weight: 600;
		font-size: $px20;
		box-shadow: 0px 10px 37px -10px rgba(242,103,93,0.8);
		@media (max-width:991px) {
			padding: 0 50px;
			height:62px;
			line-height: 60px;
			font-size: $px18;
		}
		@media (max-width:768px) {
			padding: 0 35px;
			height:52px;
			line-height: 50px;
			font-size:$px16
		}
		@media (max-width:374px) {
			padding: 0 28px;
			height:48px;
			line-height: 46px;
			font-size:$px15
		}
		&:hover{
			background-color: $white;
			color:$secondary ;
		}
		&.btn-yellow{
			border: 1px solid #fdc785;
			background-color: #fdc785;
			color: #24132f;
			box-shadow: 0px 10px 10px -10px rgba(0,0,0,0.8);
			&:hover{
				background-color: $white;
				color:#fdc785 ;
			}
		}
		&.btn-purple{
			background-color: #2c1838;
			border-color: #2c1838;
			box-shadow: 0px 10px 10px -10px rgba(0,0,0,0.8);
			&:hover{
				background-color: $white;
				color:#2c1838 ;
			}
		}
	}
	
}
/*button Style*/