.header{
  background-color: $white; 
  border-top: 5px solid $header_line;
  @media(max-width: 1499px) {
    
  }
  .logo{
    img{
      @media(max-width: 1499px) {
        max-width: 220px
      }
      @media(max-width: 991px) {
        max-width: 180px
      }
      @media(max-width: 575px) {
        max-width: 150px
      }
    }
  }
  .container {
    padding-top: 35px;
    padding-bottom: 35px;
    @media(max-width: 1499px) {
      padding-top: 40px;
    }
    @media(max-width: 991px) {
      padding-top: 25px;
      padding-bottom: 25px;
    }
    @media(max-width: 767px) {
      padding-top: 20px;
      padding-bottom: 20px;
    }
    @media(max-width: 575px) {
      padding-top: 15px;
      padding-bottom: 15px;
    }
  }
  .header-right{
    gap: 50px;
    @media(max-width: 1499px) {
      gap: 30px;
    }
    .main-menu{
      font-size: $px17;
      @media(max-width: 1499px) {
        font-size: $px16;
      }
      @media(max-width: 991px) {
        display:none;
        position: absolute;
        left: 0;
        top: 100%;
        z-index: 33;
        background: #fff;
        width: 100%;
        font-size:20px;
        nav {
          padding: 10px 0;
          ul {
            li {

              a {
                display: block;
                padding: 10px 15px;
              }
            }
          }
        }
      }
      @media(max-width: 767px) {
        font-size:$px18;
      }
      @media(max-width: 575px) {
        font-size:$px16;
        nav {
          padding: 5px 0;
          ul {
            li {
              a {
                padding: 6px 15px;
              }
            }
          }
        }
      }
      @media(max-width: 374px) {
        font-size:$px15
      }
      font-weight: 500;
      font-family: $secondary-font;
      nav{
        ul {
          gap: 45px;
          @media(max-width: 1499px) {
            gap: 35px;
          }
          li {
            text-transform: uppercase;
            &.current-menu-item ,
            &:hover{             
                color: $secondary;
            }

          }
        }
      }
    }
    .header-btn{
      margin-bottom: -12px;
      @media(max-width: 1199px) {
        display:none
      }
      .btn.btn-outline {
        @media(max-width: 1499px) {
          padding: 0 25px
        }
        
      }
    }
  }
  .top-header{
    padding-right: 15px;
    top: -5px;
    background-color: $secondary;
    z-index: 2;
    &:before{
      width: 38px;
      height: 100%;
      content: '';
      position: absolute;
      left: -37.75px;
      top: 0;
      clip-path: polygon(0 0, 100% 0, 100% 100%);
      background-color: $secondary;
    }
    .side-bg{
      position: absolute;
      height: 100%;
      width: 100%;
      left: calc(100% - 1px);
      background-color: $secondary;
    }
    .call-sec{
      padding: 0 30px 0 50px;
      &:before{
        width: 38px;
        height: 40px;
        content: '';
        position: absolute;
        left: -42px;
        top: 5px;
        z-index: -1;
        clip-path: polygon(0 0, 100% 0%, 100% 100%, 80% 100%);
        background-color: $header_line;
      }
      p {
        display: flex;
        align-items: center;
        color: $white;
        margin: 0;
        .tel-icon{
          width: 41px;
          height: 41px;
          border-radius: 50%;
          background-color: $yellow_01;
          color: $white;
          margin: 0 8px;
        }
        a {
          color: inherit;
          font-size: $px20;
          line-height: 1;
          font-weight: 700;
          letter-spacing: 1px;
          &:hover {
            color: $black;
          }
        }
      }
    }
    .social-sec{
      background-color: $purple;
      border-right: 1px solid $white_shade;
      border-left: 1px solid $black_shade;
      ul {
        li {
          a {
            color: $white;
            display: flex;
            align-items:center;
            justify-content: center;
            width: 54px; height: 52px;
            border-left: 1px solid $white_shade;
            border-right: 1px solid $black_shade;
            &:hover{
              background-color: $white;
              color: $purple;
            }
          }
        }
      }
    }
  }
}


.home{
  .hero-section {
    @media(max-width: 991px) {
      height: 600px;
      overflow: hidden;
    }
    @media(max-width: 767px) {
      height: 500px;
      overflow: hidden;
      figure {
        img {
          object-position: top center;
        }
      }
    }
    @media(max-width: 575px) {
      height: 430px;
    }
    @media(max-width: 374px) {
      height: 380px;
    }
    .heroTxt{
      z-index: 4;
      @media(max-width: 767px) {
        text-align: center;
      }
      .container{
        > * {
          max-width: 950px;
        }
        p{
          max-width: 650px;
        }
      }
    }
    
    .hero-bg {
      height: 960px;
      @media(max-width: 1499px) {
        height: 800px;
        clip-path: polygon(0 0, 100% 0%, 100% 78%, 0% 100%);
      }
      @media(max-width: 1199px) {
        height: 720px
      }
      &:before{
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background-image: url('../images/hero-shadow.png');
        background-size: cover;
        background-position: top right;
        background-repeat: no-repeat;
        z-index: 1;
        @media(min-width: 1440px) {
          width:70%
        }
        @media(max-width: 991px) {
          width:100%
        }
      }
    }
  }
}

.hero-section {
  margin-bottom: 105px;
  @media(max-width: 1199px) {
     margin-bottom: 70px
  }
  @media(max-width: 575px) {
    margin-bottom: 45px
  }  
  .hero-logo{
    left: 5%;
    top: 22%;
    z-index: 1;
    @media(max-width: 1499px) {
     max-width: 38%;
    }
    @media(max-width: 1199px) {
      max-width: 38%;
    }
  }
  .heroTxt{    
    h2{
      margin-bottom: 0;
    }
    h1{
      margin-bottom: 15px;
    }
    p{
      margin: 0;
      font-size: $px22;
      line-height: 1.5;
      color: rgba(0,0,0,.8);
      font-weight: 500;
      @media(max-width: 991px) {
        font-size: 20px;
      }
      @media(max-width: 767px) {
        font-size: 18px;
      }
      @media(max-width: 575px) {
        font-size: 16px;
      }
      @media(max-width: 374px) {
        font-size: 14px;
      }
    }
    .btn{
      margin-top: 35px;
      @media(max-width: 767px) {
         margin-top: 25px;
      }
      @media(max-width: 575px) {
         margin-top: 15px;
      }
    }
  }
}

/*Hero Section*/
body:not(.home) .hero-section {
  .heroTxt{  
    top: 45%; 
    @media(max-width: 991px) { 
      top: 50%;
    }
    h1 {
      color: #fff;
      margin: 0 auto;
      line-height: 1;
      max-width: 875px;
      .redTxt {
        color: #f2675d;
      }
    }
    p{
      max-width: 920px; margin: 0 auto;
      margin-top: 10px;
      color: #fff;
    }
  }
  .hero-bg {
    height: 730px;
    background: #fff;
    @media(max-width: 1499px) {
      height: 660px
    }
    /*@media(max-width: 1199px) {
      height: 560px
    }*/
    @media(max-width: 991px) { 
      height: 400px;
    }
    @media(max-width: 767px) { 
      height: 340px
    }
    @media(max-width: 575px) { 
      height: 290px;
    }
    @media(max-width: 374px) { 
      height: 230px
    }
    clip-path: polygon(0 0, 100% 0%, 100% 77%, 0% 100%);
    &:after{
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%; height: 100%;
      background-color: rgba(0,0,0,.68);
    }
    img{
      object-position: center top;
    }
  }
}


#nav-icon1 {
  width: 36px;
  height: 23px;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  cursor: pointer;
  padding: 0;
  span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background-color: #DB2B1E;
    border-radius: 3px;
    opacity: 1;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;
    &:nth-child(1) {
      top: 0px;
    }
    &:nth-child(2) {
      top: 10px;
    }
    &:nth-child(3) {
      top: 20px;
    }
  }
  &.open{
    span{
      &:nth-child(1) {
        top: 10px;
        transform: rotate(130deg);
      }
      &:nth-child(3) {
        top: 10px;
        transform: rotate(-130deg);
      }
      &:nth-child(2){opacity: 0}
    }
  }
}