/* WEBSITE MANAGED SERVICES */
.divider_border {
    width: 2px;
    height: 75px;
    top: -30%;
    background-color: #f2675d;

    @media (max-width: 1199px) {
        top: -85px;
    }
    @media (max-width: 991px) {
        display: none;
    }
}
.website_managed_bg {
    img {
        object-position: center !important;
    }
}
.services_program {
    padding-top: 105px;
    padding-bottom: 120px;
    @media (max-width: 991px) {
        padding-top: 82px;
        padding-bottom: 82px;
    }
    .heading {
        h3 {
            max-width: 1200px;
            margin-left: auto;
            margin-right: auto;
        }
    }
    .service_list {
        margin-top: 65px;
        ul {
            display: flex;
            flex-wrap: wrap;
            column-gap: 34px;
            justify-content: center;
            row-gap: 30px;

            li {
                position: relative;
                z-index: 99;
                background: none;
                padding: 25px 35px;
                line-height: 25px;
                font-weight: 600;
                text-align: center;
                border-radius: 20px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                /* transform: scale(1); */
                @media (max-width: 991px) {
                    font-size: 18px;
                }
                &::after {
                    content: "";
                    border: 1px solid #e1dde1;
                    position: absolute;
                    width: 100%;
                    background-color: #fff;
                    left: 0;
                    top: 0;
                    box-shadow: 0px 0px 2px 0px gray;
                    height: 100%;
                    z-index: -1;
                    border-radius: 20px;
                }

                &:hover {
                    transform: scale(1.05);
                    transition: transform 0.5s;
                    color: #fff;
                    &::after {
                        background-color: #f2675d;
                        box-shadow: 0px 0px 15px 2px #f2675d;
                        border: 0;
                        transition: box-shadow 0.3s, background-color 0.3s;
                    }
                }

                span {
                    margin-right: 9px;

                    /* Initially hide the second image */
                    img:nth-child(2) {
                        display: none;
                    }
                }

                /* Show the second image and hide the first image on hover */
                &:hover span img:first-child {
                    display: none;
                }

                &:hover span img:nth-child(2) {
                    display: block;
                }
            }
        }
    }
}
.feel_confident_happy_main {
    background: #f8f8f9;
    padding-bottom: 80px;

    .feel_confident_happy {
        background: #f8f8f9;

        .marketing-sol {
            z-index: 9;
        }
        .marketing-text {
            .heading {
                h3 {
                    font-family: $font-family-sans-serif;
                    font-size: 45px;
                    text-transform: capitalize;
                    line-height: 63px;
                    strong {
                        color: #f2675d;
                    }
                    @media (max-width: 991px) {
                        font-size: 2.25rem;
                    }
                    @media (max-width: 767px) {
                        font-size: 22px;
                        line-height: 2rem;
                    }
                }
            }
        }
        .marketing-sol {
            background-color: #f8f8f9;
            .marketing-sol-in {
                padding: 190px 0px;
                @media (max-width: 991px) {
                    -webkit-clip-path: inherit !important;
                    clip-path: inherit !important;
                    padding: 100px 0;
                }
                @media (max-width: 767px) {
                    -webkit-clip-path: inherit !important;
                    clip-path: inherit !important;
                    padding: 70px 0;
                }
            }
            .clip-path-line-top {
                height: 130px;
            }
        }
    }
}
.box_emoji {
    background-color: #f8f8f9;
    z-index: -1;
    padding-bottom: 260px;
    @media (max-width: 991px) {
        padding-bottom: 100px;
    }
    @media (max-width: 767px) {
        padding-bottom: 70px;
    }

    .bg_gray {
        .reaction_boxes {
            max-width: 995px;
            margin: 0 auto;

            .boxes {
                padding: 60px 15px;
                text-align: center;
                width: calc(16.66% + 7.5%);

                @media (max-width: 991px) {
                    width: 47.76%;
                }
                @media (max-width: 767px) {
                    width: 100%;
                }
            }
            .box_first {
                border-top: 5px solid #f2675d;
            }
            .box_secnd {
                border-top: 5px solid #fbaf41;
            }
            .box_third {
                border-top: 5px solid #90ca5c;
            }
            .box_fourth {
                border-top: 5px solid #aae177;
            }
        }
        .please_contact {
            margin-top: 65px;
            margin-bottom: 48px;
            max-width: 591px;
            margin-left: auto;
            margin-right: auto;
            @media (max-width: 767px) {
                margin-top: 30px;
                margin-bottom: 20px;
            }
            h5 {
                font-size: 25px;
                font-weight: 700;
                color: #676767;
                font-family: $font-family-sans-serif;
                border-bottom: 3px solid #676767;
            }
        }
    }
}
/* SEO PAGE */
.seo_bg {
    img {
        object-position: center 75% !important;
    }
}
.seo_intro {
    &::after {
        content: "";
        position: absolute;
        left: 50%;
        bottom: -40px;
        width: 0;
        height: 0;
        transform: translateX(-50%);
        border-left: 70px solid transparent;
        border-right: 70px solid transparent;
        border-top: 52px solid #fff;
    }
    .seo_description {
        max-width: 1072px;
        margin: 0 auto;
        p {
            font-size: 18px;
            color: #737373;
            @media (max-width: 767px) {
                font-size: 14px;
            }
        }
        h5 {
            color: #2c1839;
            font-weight: 600;
        }
    }
}
.tools_seoprocess {
    padding-top: 105px;
    padding-bottom: 120px;

    @media (max-width: 991px) {
        padding-top: 70px;
        padding-bottom: 70px;
    }
    @media (max-width: 767px) {
        padding-top: 70px;
        padding-bottom: 40px;
    }
    .heading {
        h3 {
            max-width: 1200px;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 0;
        }
    }

    .seo_process {
        padding: 90px 0 0px;
        /* background: rgb(248, 248, 249);
        background: linear-gradient(
            0deg,
            rgb(248, 248, 249) 0%,
            rgb(255, 255, 255) 100%
        ); */
        .row {
            margin: 0;
        }
        @media (max-width: 767px) {
            padding: 60px 0;
            padding-bottom: 0;
        }
        .why-item {
            padding-top: 65px;
            margin-top: -1px;
            margin-left: -1px;
            position: relative;

            .overlayLogo {
                top: -34px;
                max-width: 90%;

                @media (max-width: 991px) {
                    max-width: 70%;
                }
                @media (max-width: 575px) {
                    z-index: -1;
                }
            }
            .why-icon {
                margin-bottom: 20px;
                display: block;

                svg,
                img {
                    height: 80px;
                }
            }
            h5 {
                font-weight: 600;
                margin-bottom: 35px;
                @media (max-width: 575px) {
                    height: auto !important;
                    margin-bottom: 15px;
                }
            }
            * {
                position: relative;
                z-index: 3;
                transition: all 0.4s;
            }
            p {
                margin: 0;
            }

            padding: 65px 35px 50px;
            background-color: $white;
            border: 1px solid $gray_04;
            @media (max-width: 1499px) {
                padding: 50px 20px 30px;
            }
            @media (max-width: 575px) {
                padding-top: 35px;
            }
            &:before {
                content: "";
                height: calc(100% + 38px);
                width: calc(100% + 38px);
                left: -19px;
                top: -19px;
                position: absolute;
                background-color: $secondary;
                border-radius: 10px;
                z-index: 1;
                opacity: 0;
                transition: all 0.4s;
                @media (max-width: 767px) {
                    height: calc(100% + 16px);
                    width: calc(100% + 16px);
                    left: -8px;
                    top: -8px;
                    border-radius: 5px;
                }
            }
            &:hover {
                .why-icon {
                    svg {
                        path {
                            fill: $white;
                        }
                    }
                }
                &:before {
                    opacity: 1;
                }
                * {
                    color: $white;
                }
            }
        }
    }
}
.perfomance_roi {
    padding-top: 105px;
    padding-bottom: 120px;
    @media (max-width: 991px) {
        padding-top: 70px;
        padding-bottom: 70px;
    }
    @media (max-width: 767px) {
        padding-top: 70px;
        padding-bottom: 40px;
    }
    .heading {
        h3 {
            max-width: 1030px;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 0;
        }
    }
    .steps_boxes {
        padding-top: 65px;
        .box_width {
            width: calc(25% - 7.9%);
            border: 8px solid #eae7eb;
            border-radius: 10px;
            padding: 30px 5px;
            transition: background-color 0.5s, border-color 0.5s;

            @media (max-width: 1199px) {
                width: 22%;
            }
            @media (max-width: 991px) {
                width: 48%;
            }
            @media (max-width: 991px) {
                width: 100%;
            }
            span {
                padding-bottom: 40px;
                display: block;
                font-weight: 700;
                font-size: 19px;
            }
            .svg_icon {
                padding-bottom: 40px;
                margin-bottom: 0;
            }
            p {
                padding-bottom: 20px;
                margin-bottom: 0;
                font-size: 19px;
                font-weight: 600;
            }
            &:hover {
                background-color: #f2675d;
                border-color: #ef4b3f;
                span {
                    color: #ffff;
                }
                p {
                    color: #ffff;
                }
                .svg_icon svg path {
                    fill: #ffff;
                }
                /* } */
            }
        }
    }
}
.cosmick_media {
    padding-bottom: 80px;
     
    @media (max-width: 767px) { 
        padding-bottom: 50px;
    }
    .cosmick_bg {
        .marketing-sol {
            z-index: 9;
        }
        .marketing-text {
            .heading {
                h3 {
                    font-family: $font-family-sans-serif;
                    font-size: 45px;
                    text-transform: capitalize;
                    line-height: 63px;
                    max-width: 950px;
                    margin: 0 auto;

                    strong {
                        color: #f2675d;
                    }
                    &::before {
                        content: "";
                        display: none;
                    }
                    @media (max-width: 991px) {
                        font-size: 2.25rem;
                    }
                    @media (max-width: 767px) {
                        font-size: 22px;
                        line-height: 2rem;
                    }
                }
            }
        }
        .marketing-sol {
            .marketing-sol-in {
                padding: 190px 0px;
                @media (max-width: 991px) {
                    -webkit-clip-path: inherit !important;
                    clip-path: inherit !important;
                    padding: 100px 0;
                }
                @media (max-width: 767px) {
                    -webkit-clip-path: inherit !important;
                    clip-path: inherit !important;
                    padding: 70px 0;
                }
            }
            .clip-path-line-top {
                height: 130px;
            }
        }
    }
}
.browser_logo_ranking {
    z-index: -1;
    padding-bottom: 260px;
    @media (max-width: 991px) {
        padding-bottom: 100px;
    }
    @media (max-width: 767px) {
        padding-bottom: 70px;
    }
    @media (max-width: 575px) {
        padding-bottom: 40px;
        
    }
    .bg_gray {
        .browser_logo {
            display: flex;
            gap: 50px;
            justify-content: center;
            align-items: center;
            padding: 15px;
            margin-bottom: 90px;
            @media (max-width: 991px) {
                gap: 30px;
                flex-wrap: wrap;
                margin-bottom: 50px;
            }
            @media (max-width: 768px) {
                gap: 0;
                row-gap: 20px;
                margin-bottom: 40px;
            }
            .logo_box {
                max-width: 360px;
                width: 100%;
                box-shadow: 0px 0px 130px 0px #f8f8f8;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #fff;
                padding: 55px 0;
                border-radius: 85px 15px 15px 15px;
                border: 1px solid rgb(234, 234, 234);
                overflow: hidden;
            }
        }
        .please_contact {
            margin-top: 65px;
            margin-bottom: 48px;
            max-width: 591px;
            margin-left: auto;
            margin-right: auto;
            @media (max-width: 767px) {
                margin-top: 50px;
                margin-bottom: 50px;
            }
            h5 {
                font-size: 25px;
                font-weight: 700;
                color: #676767;
                font-family: $font-family-sans-serif;
                border-bottom: 3px solid #676767;
                @media (max-width: 767px) {
                    font-size: 20px;
                    padding-bottom: 12px;
                }
            }
        }
        .benifits_overviewimg {
            box-shadow: 0 280px 39px -6px #fbfbfb;
        }
    }
}
/* OUR HISTORY */
.ourhistory_home {
    img {
        object-position: center 70% !important;
    }
    p {
        max-width: 925px !important;
    }
}
.yers_event {
    padding-bottom: 120px;
    .center_line {
        width: 35px;
        height: 326px;
        margin: 0 auto;
        /* background-color: #f2675d; */
        position: relative;
        &::before {
            content: "";
            width: 0;
            height: 0;
            border-left: 18px solid transparent;
            border-right: 19px solid transparent;
            border-bottom: 17px solid #f2675d;
            position: absolute;
            top: -16px;
            right: 0px;
            left: -1px;
        }
        &:after {
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            width: 0;
            height: 0;
            border-bottom: 13px solid #ffff;
            border-left: 17px solid transparent;
            border-right: 17px solid transparent;
        }

        .date,
        .right_date {
            /* background-color: $yellow_02; */
            height: 80px;
            width: 300px;
            top: 50px;
            right: -20px;
            color: $white;
            font-size: $px15;
            font-weight: 600;
            z-index: 99;
            font-family: $secondary-font;
            @media (max-width: 767px) {
                width: 175px;
            }
            &:after {
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 5px 0 5px 20px;
                border-color: transparent transparent transparent #fcac4b;
                content: "";
                position: absolute;
                top: -5px;
                right: 0;
                z-index: -1;
            }

            &:after {
                top: auto;
                bottom: -5px;
            }
            .left_arrow {
                position: absolute;
                left: 0;
                bottom: 0;
                width: 0;
                height: 0;
                top: 50%;
                border-left: 29px solid white;
                border-top: 41px solid transparent;
                border-bottom: 40px solid transparent;
            }
            .yers_num {
                position: absolute;
                left: 35%;
                top: 5px;
                font-size: 35px;
                @media (max-width: 767px) {
                    top: 15px;
                    font-size: 28px;
                }
            }
        }
        .right_date {
            right: auto;
            left: -20px;
            &:after {
                right: auto;
            }
            .left_arrow {
                position: absolute;
                left: 77%;
                bottom: 0;
                width: 0;
                height: 0;
                top: 50%;
                border-right: 29px solid white;
                border-top: 41px solid transparent;
                border-bottom: 40px solid transparent;

                border-left: 40px solid transparent;
            }

        }
        .contain_right,.contain_left {
            background: linear-gradient(
                258deg,
                rgba(0, 0, 0, 0) 0%,
                rgba(243, 243, 243, 1) 87%
            );
            padding-top: 42px;
            padding-bottom:15px;
            padding-left: 60px;
            width: 650px;
            left: 35px;
            position: absolute;
            top: 50px;
            h5 {
                font-size: 23px;
                font-weight: 600;
                margin-bottom: 23px;
            }
            p{
                margin-bottom: 0;
            }
        }
        .contain_left{
            right: 34px;
            left: auto;
            background: linear-gradient(76deg, rgba(0,0,0,0) 0%, rgba(243,243,243,1) 62%);
            padding-bottom: 10px;
            padding-right: 40px;
            padding-left: 0;
            text-align: right;
            padding-top: 20px;
        }
    }
    .center_line_pink{
        background-color: #f48379;
        &::before {
            border-bottom-color: #f48379;
        }
        .date{
            background-color: #f2675d;
            &::after{
                border-color: transparent transparent transparent #f2675d;

            }
        }
       
    }
    .center_line_blue{
        background-color: #412352;
        &::before {
            border-bottom-color: #412352;
        }
        .right_date {
           background-color: #2d1839;
            &::after{
                border-color: transparent transparent transparent #23122d;

            }
        }
    }
    .center_line_yellow{
        background-color: #fdd3a2;
        &::before {
            border-bottom-color: #fdd3a2;
        }
        .date{
            background-color: #fdc785;
            &::after{
                border-color: transparent transparent transparent #fdbe72;

            }
        }
    }
    .center_line_gray{
        background-color: #7c7c7c;
        &::before {
            border-bottom-color: #7c7c7c;
        }
        .date{
            background-color: #7c7c7c;
            &::after{
                border-color: transparent transparent transparent #777777;

            }
        }
    }
}
