

/*Responsive Breakpoint Start */
@mixin media-min-768 {
  @media screen and (min-width: 768px) {
    @content;
  }
}
@mixin media-min-992 {
  @media screen and (min-width: 992px) {
    @content;
  }
}
@mixin media-1199 {
  @media screen and (max-width: 1199px) {
    @content;
  }
}
@mixin media-991 {
  @media screen and (max-width: 991px) {
    @content;
  }
}
@mixin media-767 {
  @media screen and (max-width:  767px) {
    @content;
  }
}
@mixin media-575 {
  @media screen and (max-width:  575px) {
    @content;
  }
}
@mixin media-374 {
  @media screen and (max-width:  374px) {
    @content;
  }
}

/*Responsive Breakpoint END */


/*
@include media-min-768
@include media-min-992

@include media-1199
@include media-991
@include media-767
@include media-575
@include media-374

*/
