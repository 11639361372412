strong{font-weight: 700}
figure{margin: 0}
ul ,ol {margin: 0; padding: 0}
img{max-width: 100%; }
a {transition: all .3s; color: inherit; text-decoration: none}

body{
	font-size: 16px;
	line-height: $line-height-base;
	font-family: $font-family-sans-serif;
	color: $body-color;
	background-color: $white;	
	@include media-767 {
		font-size: 15px;	
	}
	@include media-374 {
		font-size: 13px;	
	}
}
p{
	margin-bottom: 35px;
	@media(max-width: 991px) {
		margin-bottom: 30px;
	}
	@media(max-width: 575px) {
		margin-bottom: 20px;
	}

}
.container{
	max-width: 1500px;
	width: 100%;
	padding: 0 15px;
	margin: 0 auto;
}
.container_sm {
	max-width: 1200px;
}
.container-fluid{
	width: 100%;
	max-width: 100%;
	padding: 0 30px;
	@include media-1199 {
		padding: 0 15px;
	}
}
h1 , h2 , h3 , h4 , h5 , h6 {
	margin: 0;
	padding: 0;
}
h1{
	font-size:$h1-font-size ;
	line-height: 1.2;
	color: $headings-color;
	font-weight: 800;
	text-transform: uppercase;
	@media(max-width: 1499px) {
		font-size: 68px
	}
	@media(max-width: 1199px) {
		font-size: $px60
	}
	@media(max-width: 991px)  {
		font-size: $px48
	}
	@media(max-width: 767px)  {
		font-size: $px38
	}
	@media(max-width: 575px)  {
		font-size: $px30
	}
	@media(max-width: 374px)  {
		font-size: $px26
	}
}

h2{
	font-size:$h2-font-size ;
	line-height: 1.2;
	color: #170d1e;
	text-transform: uppercase;
	opacity: .8;
	@media(max-width: 1499px) {
		font-size: 60px
	}
	@media(max-width: 1199px) {
		font-size: $px48
	}
	@media(max-width: 991px)  {
		font-size: $px36
	}
	@media(max-width: 767px)  {
		font-size: $px30
	}
	@media(max-width: 575px)  {
		font-size: $px24
	}
	@media(max-width: 374px)  {
		font-size: $px20
	}
}
h3{
	font-size:$h3-font-size ;
	line-height: 1.18;
	position: relative;
	color: #342440;
	text-transform: uppercase;
	padding-bottom: 27px; margin-bottom: 35px;
	&:before{
		content: '';
		position: absolute;
		left: 0;
		width: 110px; height: 3px;
		background-color: #f2665d;
		bottom: 0;
	}
	strong{
		color: #f2665d;
	}
	@media(max-width: 1499px) {
		font-size:$px48
	}	
	@include media-1199 {
		font-size: $px40;
	}
	@include media-991 {
		font-size: $px36;
	}
	@media(max-width: 767px)  {
		font-size: $px30;
	}
	@media(max-width: 575px)  {
		font-size: $px26;
	}
	@media(max-width: 374px)  {
		font-size: $px22;
	}
}
h4{
	font-size:$h4-font-size ;
	font-weight: 600;
	@media(max-width: 1499px) {
		font-size:$px30
	}	
	@media(max-width: 1199px) {
		font-size:$px26
	}	
	@media(max-width: 991px) {
		font-size:$px30
	}	
	@media(max-width: 767px) {
		font-size:$px24
	}	
	@media(max-width: 575px) {
		font-size:$px22
	}	
	@media(max-width: 374px) {
		font-size:$px20
	}	
}
h5{
	font-size:$h5-font-size ;
	line-height: 1.22;
	@include media-1199 {
		font-size: $px20
	}
	@include media-767 {
		font-size: $px18
	}
	@include media-374 {
		font-size: $px16
	}
}
h6{
	font-size:$h6-font-size ;
	line-height: 1.5;
	font-weight: 500;
	@include media-767 {
		font-size: $px18;
	}
	@include media-575 {
		font-size: $px16;
	}
	@include media-374 {
		font-size: $px14;
	}
}

.img-0 ,
.img-cover {font-size: 0;}
.img-cover {
	> img{
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.purple_bg {
	color: #fff;
	background-color: #2d1839;
}

.clip-path-top-sm ,
.clip-path-top{
		content: '';
		position: absolute;
		left: 0;
		background-color: #fff;
		height: 200px;
		width: 100%;
		top: -199px;
		z-index: -1;
		clip-path: polygon(100% 0, 0% 100%, 100% 100%);
		&.clip-btm{
			top: auto;
			bottom: -1px;
			z-index: 1;
			height: 180px;
		}
}
.clip-path-top-sm {
	width: 700px;
	height: 310px;
	clip-path: polygon(0 0, 0% 100%, 100% 100%);
	top: -285px;
	@media(max-width: 991px) {
    top: -200px;
	}
	&.clip-btm{
		left: auto;
		right: 0;
		bottom: -70px;
    height: 240px;
    z-index: -1;
		clip-path: polygon(100% 0, 0 0, 100% 100%);
		@media(max-width: 991px) {
			bottom: -20px;
    	height: 180px;
		}
	}
}

.clip-path-line-top{
		content: "";
    position: absolute;
    left: 0;
    background-color: #fff;
    height: 193px;
    width: 80%;
    top: -180px;
    z-index: -1;
    /*clip-path: polygon(100% 0, 0% 84%, 98% 100%);*/
    clip-path: polygon(.5% 84%, 100% 0%, 99.5% 19%, 0% 100%);

    &.clip-btm{
    	top: auto;
    	left: auto;
    	right: 0;
    	clip-path: polygon(0 85%, 100% 0%, 100% 15%, 0% 100%);
    	bottom: 10px;
	    z-index: 3;
	    height: 169px;
    }
}
.btm-pattern{
	clip-path: polygon(0 0, 100% 0%, 100% 82%, 0% 100%);
}
.blueXt{
	color: #342440;
}
.pinktxt{
	color: #f2675d;
}

input[type=number] {
  -moz-appearance: textfield;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* For IE10 */
select::-ms-expand {
  display: none;
}
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
}
.gray-bg{
	background-color: #f8f8f9;
}

.darkblue_bg{
	background-color: #2d1839;
}
.lightyellow_bg{
	background-color: #fdc785;
}
.darkgray_bg{
	background-color: #7c7c7c;
}
.redlight_bg{
background-color: #f2675d;
}
.lightpink{
	background-color: #f48379;
}
.slick-track
{
    display: flex !important;
}

.slick-slide
{
    height: inherit !important;
}

@media(max-width: 991px) {
	.clip-path-top ,
	.clip-path-top-sm ,
	.clip-path-line-top {
		display: none !important;
	}
	.btm-pattern {
		clip-path: initial !important;
	}
}