$px96 : 6rem;
$px80 : 5rem;
$px72 : 4.5rem;
$px70 : 3.875rem;
$px64 : 4rem;
$px60: 3.75rem;
$px54: 3.375rem;
$px48 : 3rem;
$px45 : 2.813rem;
$px42 : 2.688rem;
$px40 : 2.5rem;
$px39 : 2.438rem;
$px38 : 2.438rem;
$px37 : 2.31rem;
$px36 : 2.25rem;
$px35 : 2.188rem;
$px34 : 2.125rem;
$px32 : 2rem;
$px31 : 1.938rem;
$px30 : 1.875rem;
$px28 : 1.75rem;
$px27 : 1.688rem;
$px26 : 1.625rem;
$px25 : 1.567rem;
$px24 : 1.5rem;
$px23 : 1.438rem;
$px22 : 1.375rem;
$px21 : 1.313rem;
$px20 : 1.25rem;
$px19 : 1.188rem;
$px18 : 1.125rem;
$px17 : 1.063rem;
$px16 : 1rem;
$px15 : 0.938rem;
$px14 : 0.875rem;
$px13 : 0.813rem;
$px12 : 0.75rem;
$px11 : 0.688rem;
$px10 : 0.625rem;


$body-bg :#fff;
$body-color: rgba(0,0,0,.9);
$primary: #2c1839;
$secondary: #f2665d;
$trinary-color: #ed2f1f;
$cream_color : #fff8f0;
$orange_2 : #f38704;
$header_line : #545454;
$gray_01 : #e0dde1;
$gray_02 : #626262;
$gray_03 : #c2c2c2;
$gray_04 : #c9c9c9;
$gray_05 : #DEDEDE;
$gray_dark : #5D5E5E;
$yellow_01 : #fac380;
$yellow_02 : #fac380;
$purple : #2d1839;
$purple_02 : #2d1839;
$black_shade: #1e1025;
$white_shade: #4e2963;

$radius-4 : 4px;

$font-family-sans-serif: "Poppins", sans-serif;
$secondary-font: "Montserrat", sans-serif;

$link-decoration: none;
$link-hover-color: #000;
$font-size-base: $px16;
$font-weight-base: 400;
$container-padding-x: 15px !default;
$line-height-base: 1.75;


$headings-font-family: $secondary-font !default;
$headings-line-height: normal !default;
$headings-color: $primary !default;
$headings-font-weight: normal !default;
$box-radius : 15px;



/*Bootstrap Heading variable*/
$h1-font-size:80px;
$h2-font-size:68px;
$h3-font-size:60px;
$h4-font-size:35px;
$h5-font-size:24px;
$h6-font-size:20px;

/*Bootstrap buttons variables*/
$btn-border-radius: 0;
$btn-border-width:  0;
$btn-link-color : #fff;
$btn-font-family: $font-family-sans-serif;
$btn-font-weight:  normal;
$btn-font-size: $px16;
$btn-box-shadow: none;
$btn-line-height: normal;
$btn-white-space: nowrap;


